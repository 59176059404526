<script setup lang="ts">
import type { SeriesData, DailySpendCoverageDay } from '@azure/services/savings.models';
import type { MonthStart } from '@console/services/api.models';

import _ from 'lodash';
import moment from 'moment';
import { computed, onMounted, ref, watch } from 'vue';

import { getCurrencyFormat, percentFormat } from '@console/components/charts/utility';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps<{
  dailySpendCoverage: DailySpendCoverageDay[];
  monthStart: MonthStart;
}>();

const xAxis = computed(() => {
  const month = moment.utc(props.monthStart.key).format('MMM');
  const monthAndDay = (day: DailySpendCoverageDay) => `${month} ${day.day}`;
  const categories = _.map(props.dailySpendCoverage, monthAndDay);
  return { categories };
});

const yAxis = computed(() => {
  const rightTickPositions = _.range(0, 120, 20);
  return [
    {
      min: 0,
      tickAmount: rightTickPositions.length,
      title: {
        text: null,
      },
      labels: {
        format: getCurrencyFormat(),
      },
    },
    {
      tickPositions: rightTickPositions,
      title: {
        text: null,
      },
      labels: {
        format: percentFormat,
      },
      opposite: true,
    },
  ];
});

const filteredSeries = computed<SeriesData[]>(() => legend.value.filter(s => s.legendSelected));

const series = computed(() => {
  // compute

  // This is the order the items will be stacked in the chart (the first will be on the top)
  const seriesDefs = [
    // Force this series to be the first in the legend (normally it would be last)
    { name: 'Compute Usage', field: 'compute_usage_normalized', type: 'column', color: '#a7a3ff', legendOrder: 0 },
    { name: 'Smart Spend Coverage', field: 'smart_spend_coverage_normalized', type: 'area', color: '#8fffdf' },
    { name: 'Base Spend Coverage', field: 'base_spend_coverage_normalized', type: 'area', color: '#00c58c' },
    { name: 'Inherited Spend Coverage', field: 'inherited_spend_coverage_normalized', type: 'area', color: '#495057' },
    { name: 'Unbilled Spend Coverage', field: 'unbilled_spend_coverage_normalized', type: 'area', color: '#e6e6e6' },

    // Force this series to be the last in the legend (normally it would be first)
    {
      name: 'Overall Spend Coverage',
      field: 'overall_spend_coverage_percentage',
      type: 'line',
      color: '#fcbe2c',
      yAxis: 1,
      legendOrder: 100,
    },
  ];

  const columnOptions = (s: {
    name: string;
    field: string;
    type: string;
    color: string;
    legendOrder?: undefined | number;
    yAxis?: undefined | number;
  }) => ({
    borderColor: s.color, // For columnar series, use the fill color as the border color (the default is white)
  });
  const areaOrLineOptions = {
    stacking: 'normal',
    fillOpacity: '0.5',
    marker: {
      enabled: false,
    },
  };
  const dollarTooltip = { valuePrefix: '$', valueSuffix: '', valueDecimals: 2 };
  const percentTooltip = { valuePrefix: '', valueSuffix: '%', valueDecimals: 1 };

  const getSeriesData = (field: string) => {
    if (field === 'compute_usage_normalized') return props.dailySpendCoverage.map(d => d.compute_usage_normalized);
    else if (field === 'smart_spend_coverage_normalized') {
      return props.dailySpendCoverage.map(d => d.smart_spend_coverage_normalized);
    } else if (field === 'base_spend_coverage_normalized') {
      return props.dailySpendCoverage.map(d => d.base_spend_coverage_normalized);
    } else if (field === 'inherited_spend_coverage_normalized') {
      return props.dailySpendCoverage.map(d => d.inherited_spend_coverage_normalized);
    } else if (field === 'unbilled_spend_coverage_normalized') {
      return props.dailySpendCoverage.map(d => d.unbilled_spend_coverage_normalized);
    } else if (field === 'overall_spend_coverage_percentage') {
      return props.dailySpendCoverage.map(d =>
        d.overall_spend_coverage_percentage ? d.overall_spend_coverage_percentage * 100 : undefined
      );
    } else {
      return [];
    }
  };

  return (
    seriesDefs
      .map((s, index) => ({
        label: s.name,
        tooltip: s.yAxis === 1 ? percentTooltip : dollarTooltip,
        data: getSeriesData(s.field),
        // Reverse the order of the legend so that the last item (bottom) is the leftmost
        legendOrder: seriesDefs.length - index,
        legendSelected: true,
        dashStyle: 'solid',
        yAxis: 0,
        // Apply the options specific to the series type
        ...(s.type === 'column' ? columnOptions(s) : areaOrLineOptions),
        // Allow the series to override any of the above properties
        ...s,
      }))
      // Remove any series without any data points
      .filter(s => s.data.some(d => !!d))
  );
});

const legend = ref<SeriesData[]>([]);

const buildLegend = () => {
  legend.value = series.value;
};

onMounted(buildLegend);
watch(series, buildLegend);
</script>

<template>
  <PanelSection header="Daily Spend Coverage">
    <div>
      <ChartLegend v-model="legend" />
      <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" />
    </div>
  </PanelSection>
</template>
