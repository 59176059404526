<script lang="ts" setup>
import type { BillingScopeResponse } from '@azure/services/billingScope';
import type { CreateServicePrincipalResponse } from '@azure/services/servicePrincipal';
import type { Tenant } from '@azure/services/subscriptions';

import _ from 'lodash';
import { onMounted, ref } from 'vue';

import { get, validateTenantAccess } from '@azure/services/servicePrincipal';
import { getSubscriptionsGroupedByTenant } from '@azure/services/subscriptions';
import { useAzureStore } from '@azure/state';

import AzureTenantFilterList from '@azure/components/tenants/AzureTenantFilterList.vue';
import PageHeader from '@shared/design/PageHeader.vue';

const azureState = useAzureStore();
const loading = ref(true);
const tenants = ref<Tenant[]>([]);
const validating = ref(false);
const billingScope = ref<BillingScopeResponse | undefined>(undefined);
const servicePrincipal = ref<CreateServicePrincipalResponse | undefined>(undefined);
const validationError = ref(false);
const validationErrorMessage = ref('');

onMounted(async () => {
  loading.value = true;
  const billingScopeId = azureState.getActiveBillingScopeId();
  if (billingScopeId) {
    // get the billing scope first
    billingScope.value = await azureState.getBillingScopeById(billingScopeId);

    // if the billingscope has a service principal id, we need to get the service principal
    if (billingScope.value?.service_principal_id) {
      servicePrincipal.value = await get(billingScope.value?.service_principal_id);
    }

    const result = await getSubscriptionsGroupedByTenant(billingScopeId);
    tenants.value = result;
  }
  loading.value = false;
});

const validateTenant = async (tenant: Tenant) => {
  validating.value = true;

  // clear the validation error
  validationError.value = false;
  validationErrorMessage.value = '';

  // if the service principal is undefined, we can't validate
  if (servicePrincipal.value?.id !== undefined) {
    const validationResult = await validateTenantAccess(servicePrincipal.value?.id, tenant.tenant_id, {
      billing_scope_id: billingScope.value?.id,
    });

    tenant.validated = validationResult.status === 'Validated';

    // if the validation was not successful, we need to show an error message
    if (!tenant.validated) {
      validationErrorMessage.value = validationResult.message;
      validationError.value = true;
      validating.value = false;
      return;
    }
  }

  validating.value = false;
};

const validateAll = () => {
  _.each(tenants.value, t => {
    validateTenant(t);
  });
};
</script>

<template>
  <div>
    <PageHeader wrap-utility>
      <h1>Azure Tenants</h1>
      <AzureTenantFilterList
        :validating="validating"
        :validate-tenant="validateTenant"
        :validate-all="validateAll"
        :tenants="tenants"
        :validation-error="validationError"
        :validation-error-message="validationErrorMessage"
      />
    </PageHeader>
  </div>
</template>
