<script lang="ts" setup>
import type { AwsOrganizationResponse, OrganizationSettingsSummary } from '@console/services/api.models';

import _ from 'lodash';
import { computed } from 'vue';

import { useVuexStore } from '@console/state/vuex/store';

import SettingRow from '@console/components/settings/SettingRow.vue';
import SettingsPanel from '@console/components/settings/SettingsPanel.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import StatusPill from '@shared/design/StatusPill.vue';

const props = defineProps<{
  settings: OrganizationSettingsSummary;
}>();

const vuexStore = useVuexStore();
const isReseller = computed<boolean>(() => vuexStore.getters['customer/isReseller']);
const selectedOrganization = computed<AwsOrganizationResponse>(() => vuexStore.getters['aws/selectedOrganization']);
const orgRegionDisplayNames = computed<Record<string, string>>(
  () => vuexStore.getters['aws/selectedOrganizationRegionDisplayNames']
);

const useFlexTerminology = computed(() => !selectedOrganization.value?.adm_compute_has_smart_component);

const enabledFlexAllUpfront = computed(() => !!props.settings?.flex_all_upfront_override_days);
const enabledAcceleratedDiscountShift = computed(() => !!props.settings?.flex_accelerated_discount_shift_months);
const flexAllUpfrontDays = computed(() => props.settings?.flex_all_upfront_override_days ?? 40);
const flexBoostEnabled = computed(() => props.settings?.enable_flex_boost);
const acceleratedDiscountShiftMonths = computed(() => props.settings?.flex_accelerated_discount_shift_months ?? 18);

const computeShrinkAllowance = computed(() => {
  const csa = props.settings?.flex_compute_shrink_allowance_percentage;
  return _.isNumber(csa) ? `${csa}%` : '-';
});

const regionPrepaymentOverrides = computed(() => {
  // If all regions are set to No Upfront, there aren't any overrides
  const preferences = props.settings?.flex_pre_payment_region_preference ?? [];
  if (preferences.every(p => p.preference === 'No Upfront')) {
    return [];
  }

  return preferences.map(p => ({
    region: orgRegionDisplayNames.value[p.region],
    value: p.preference,
  }));
});
</script>

<template>
  <SettingsPanel :header="useFlexTerminology ? 'Flex' : 'Smart Reserved Instances'">
    <template v-slot:utility>
      <LastUpdated :value="settings.flex_last_updated" />
    </template>
    <SettingRow>
      <template v-slot:label> Compute Shrink Allowance Target </template>
      <template v-slot:description>
        <template v-if="isReseller">
          Desired amount of arbitrageable compute usage decline our service should absorb without commitment becoming
          unutilized
        </template>
        <template v-else>
          Desired amount of compute usage decline our service should absorb without commitment becoming unutilized
        </template>
      </template>
      <template v-slot:value>
        {{ computeShrinkAllowance }}
      </template>
    </SettingRow>
    <SettingRow>
      <template v-slot:label> Prepayment Preference </template>
      <template v-slot:description> Upfront prepayment preference for newly deployed Convertible RIs </template>
      <template v-slot:value>
        <div v-if="regionPrepaymentOverrides.length === 0">
          <div v-if="settings.flex_pre_payment_preference" class="font-weight-bold">
            {{ settings.flex_pre_payment_preference }}
          </div>
          <div v-else>-</div>
        </div>
      </template>
    </SettingRow>
    <SettingRow v-for="override in regionPrepaymentOverrides" :key="override.region">
      <template v-slot:label>
        <div class="pl-5">
          {{ override.region }}
        </div>
      </template>
      <template v-slot:value>
        <span class="font-weight-bold">{{ override.value }}</span>
      </template>
    </SettingRow>
    <div class="mt-4">
      <span class="text-uppercase font-weight-bold">Advanced</span>
    </div>
    <SettingRow v-if="flexBoostEnabled">
      <template v-slot:label> Flex Boost </template>
      <template v-slot:description>
        Automatically deploy a layer of 3 year No Upfront Standard RIs to boost Flex savings
      </template>
      <template v-slot:value>
        <StatusPill :enabled="flexBoostEnabled" variant="primary">
          <span v-if="flexBoostEnabled">Enabled</span>
          <span v-else>Disabled</span>
        </StatusPill>
      </template>
    </SettingRow>
    <SettingRow>
      <template v-slot:label> Monthly All Upfront {{ useFlexTerminology ? 'Flex' : '' }} </template>
      <template v-slot:description>
        Use All Upfront prepayment for new
        {{ useFlexTerminology ? 'Flex Convertible RI' : 'Smart Reserved Instance' }} commitment when the remaining term
        is {{ flexAllUpfrontDays }} days or less (i.e. prepayment is monthly OPEX)
      </template>
      <template v-slot:value>
        <StatusPill :enabled="enabledFlexAllUpfront" variant="primary">
          <span v-if="enabledFlexAllUpfront">Enabled</span>
          <span v-else>Disabled</span>
        </StatusPill>
      </template>
    </SettingRow>
    <SettingRow>
      <template v-slot:label> Accelerated Discount Shift </template>
      <template v-slot:description>
        Progressively shift 1 year
        {{ useFlexTerminology ? 'Flex Convertible RI' : 'Smart Reserved Instance' }} commitment to the closest 3 year
        discount expiration point (still honoring the Compute Shrink Allowance Target) when the remaining term is
        {{ acceleratedDiscountShiftMonths }} months or less
      </template>
      <template v-slot:value>
        <StatusPill :enabled="enabledAcceleratedDiscountShift" variant="primary">
          <span v-if="enabledAcceleratedDiscountShift">Enabled</span>
          <span v-else>Disabled</span>
        </StatusPill>
      </template>
    </SettingRow>
  </SettingsPanel>
</template>
