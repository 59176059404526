<script lang="ts" setup>
import type { OrganizationSettingsSummary } from '@console/services/api.models';

import { computed } from 'vue';

import NumberHelpers from '@shared/utilities/number_helpers';

import SettingRow from '@console/components/settings/SettingRow.vue';
import SettingsPanel from '@console/components/settings/SettingsPanel.vue';
import LastUpdated from '@shared/design/panels/LastUpdated.vue';
import StatusPill from '@shared/design/StatusPill.vue';

const props = defineProps<{
  settings: OrganizationSettingsSummary;
}>();

const isCspTargetManual = computed(() => !!props.settings.adm_compute_sp_ladder_csp_monthly_target);
</script>

<template>
  <SettingsPanel header="Smart Savings Plans">
    <template #utility>
      <LastUpdated :value="settings.adm_compute_sp_ladder_settings_last_updated" />
    </template>
    <div class="sectionHeader">
      <span class="text-uppercase font-weight-bold">Compute Savings Plans</span>
    </div>
    <SettingRow class="settingRow" data-test-id="base-target">
      <template #label> Adaptive Laddering </template>
      <template #description> Deploy a layer of intelligently laddered Compute Savings Plan spend coverage </template>
      <template #value>
        <StatusPill variant="primary">Enabled</StatusPill>
      </template>
    </SettingRow>
    <SettingRow v-if="isCspTargetManual" class="settingRow">
      <template #label> Ladder Target </template>
      <template #description>
        Desired amount of laddered Compute Savings Plan spend coverage to maintain (in on-demand equivalent dollars for
        a 30 day month)
      </template>
      <template #value>
        <strong>{{ NumberHelpers.formatDollars(settings.adm_compute_sp_ladder_csp_monthly_target, 0) }}</strong>
      </template>
    </SettingRow>
    <SettingRow class="settingRow">
      <template #label> Ladder Target Allocation </template>
      <template #description>
        Desired distribution of laddered Compute Savings Plan spend coverage across 1 and 3 year terms
      </template>
      <template #value>
        <div>
          1 Year:
          <strong
            >{{ NumberHelpers.formatNumber(settings.adm_compute_sp_ladder_csp_one_year_target_percentage) }}%</strong
          >
        </div>
        <div class="pt-2">
          3 Year:
          <strong
            >{{ NumberHelpers.formatNumber(settings.adm_compute_sp_ladder_csp_three_year_target_percentage) }}%</strong
          >
        </div>
      </template>
    </SettingRow>
    <SettingRow class="settingRow">
      <template #label> Ladder Prepayment Preference </template>
      <template #description>
        Upfront prepayment preference for newly deployed Compute Savings Plan ladder rungs
      </template>
      <template #value>
        <strong>{{ settings.adm_compute_sp_ladder_prepayment_preference }}</strong>
      </template>
    </SettingRow>
  </SettingsPanel>
</template>

<style lang="scss" scoped>
.sectionHeader {
  margin-top: 0.75rem;
}
</style>
