<script lang="ts" setup>
import type { PortfolioAllocation } from '@azure/services/savings.models';
import type { PropType } from 'vue';

import { computed } from 'vue';

import NumberHelpers from '@shared/utilities/number_helpers';

import DonutChart from '@console/components/charts/DonutChart.vue';
import Percentage from '@shared/components/Percentage.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';
import TextTooltip from '@shared/design/TextTooltip.vue';

const LABEL_TEXT_DARK = '#212529';
const LABEL_TEXT_LIGHT = '#ffffff';

interface ChartData {
  id?: string;
  name: string;
  label: string;
  labelColor: string;
  y?: number;
  color: string;
  legendSelected: boolean;
  legendOrder: number;
}

function labelOptions(point: ChartData) {
  if (point.y != null && point.y < 5) {
    return { dataLabels: { enabled: false } };
  }
  return {
    dataLabels: {
      enabled: true,
      format: '{point.percentage:.1f}%',
      distance: '-25%', // innerSize is 50%, so this is half that
      style: {
        color: point.labelColor,
        textOutline: '0px',
        fontWeight: '400',
      },
    },
  };
}

const props = defineProps({
  portfolioAllocation: {
    type: Object as PropType<PortfolioAllocation>,
    required: true,
  },
});

const percentage = (val?: number) => (val ? val * 100 : undefined);

const data = computed(() => {
  // enhance chart data with additional label options when we have data for the section
  return chartData.value
    .filter(d => d.y != null && d.y > 0)
    .reduce<ChartData[]>((acc, data) => [...acc, { ...data, ...labelOptions(data) }], []);
});

const chartData = computed(() => {
  const label = (discount?: number) => {
    return `receiving ${NumberHelpers.formatNumber(discount, 1)}% discount`;
  };

  return [
    {
      name: `Base: 1 Year (${label(percentage(props.portfolioAllocation.base_one_year_discount_percentage))})`,
      label: `Base: 1 Year (${label(percentage(props.portfolioAllocation.base_one_year_discount_percentage))})`,
      y: percentage(props.portfolioAllocation.base_three_year_percentage),
      color: '#a7a3ff',
      labelColor: LABEL_TEXT_LIGHT,
      legendSelected: true,
      legendOrder: 0,
    },
    {
      name: `Base: 3 Year (${label(percentage(props.portfolioAllocation.base_three_year_discount_percentage))})`,
      label: `Base: 3 Year (${label(percentage(props.portfolioAllocation.base_three_year_discount_percentage))})`,
      y: percentage(props.portfolioAllocation.base_three_year_percentage),
      color: '#5c54ff',
      labelColor: LABEL_TEXT_LIGHT,
      legendSelected: true,
      legendOrder: 1,
    },
    {
      name: `Smart: 1 Year (${label(percentage(props.portfolioAllocation.smart_one_year_discount_percentage))})`,
      label: `Smart: 1 Year (${label(percentage(props.portfolioAllocation.smart_one_year_discount_percentage))})`,
      y: percentage(props.portfolioAllocation.smart_one_year_percentage),
      color: '#8fffdf',
      labelColor: LABEL_TEXT_DARK,
      legendSelected: true,
      legendOrder: 2,
    },
    {
      name: `Smart: 3 Year (${label(percentage(props.portfolioAllocation.smart_three_year_discount_percentage))})`,
      label: `Smart: 3 Year (${label(percentage(props.portfolioAllocation.smart_three_year_discount_percentage))})`,
      y: percentage(props.portfolioAllocation.smart_three_year_percentage),
      color: '#00c58c',
      labelColor: LABEL_TEXT_LIGHT,
      legendSelected: true,
      legendOrder: 3,
    },
    {
      name: `Inherited: 1 Year (${label(
        percentage(props.portfolioAllocation.inherited_one_year_discount_percentage)
      )})`,
      label: `Inherited: 1 Year (${label(
        percentage(props.portfolioAllocation.inherited_one_year_discount_percentage)
      )})`,
      y: percentage(props.portfolioAllocation.inherited_one_year_percentage),
      color: '#feebbf',
      labelColor: LABEL_TEXT_DARK,
      legendSelected: true,
      legendOrder: 4,
    },
    {
      name: `Inherited: 3 Year (${label(
        percentage(props.portfolioAllocation.inherited_three_year_discount_percentage)
      )})`,
      label: `Inherited: 3 Year (${label(
        percentage(props.portfolioAllocation.inherited_three_year_discount_percentage)
      )})`,
      y: percentage(props.portfolioAllocation.inherited_three_year_percentage),
      color: '#fcbe2c',
      labelColor: LABEL_TEXT_LIGHT,
      legendSelected: true,
      legendOrder: 5,
    },
    {
      name: `Unbilled: 1 Year (${label(percentage(props.portfolioAllocation.unbilled_one_year_discount_percentage))})`,
      label: `Unbilled: 1 Year (${label(percentage(props.portfolioAllocation.unbilled_one_year_discount_percentage))})`,
      y: percentage(props.portfolioAllocation.unbilled_one_year_percentage),
      color: '#e6e6e6',
      labelColor: LABEL_TEXT_DARK,
      legendSelected: true,
      legendOrder: 6,
    },
    {
      name: `Unbilled: 3 Year (${label(
        percentage(props.portfolioAllocation.unbilled_three_year_discount_percentage)
      )})`,
      label: `Unbilled: 3 Year (${label(
        percentage(props.portfolioAllocation.unbilled_three_year_discount_percentage)
      )})`,
      y: percentage(props.portfolioAllocation.unbilled_three_year_percentage),
      color: '#999999',
      labelColor: LABEL_TEXT_DARK,
      legendSelected: true,
      legendOrder: 7,
    },
    {
      id: 'Other',
      name: `Other (${label(percentage(props.portfolioAllocation.on_demand_negotiated_savings_discount_percentage))})`,
      label: `Other (${label(percentage(props.portfolioAllocation.on_demand_negotiated_savings_discount_percentage))})`,
      y: percentage(props.portfolioAllocation.on_demand_negotiated_savings_percentage),
      color: '#a7a3ff',
      labelColor: LABEL_TEXT_LIGHT,
      legendSelected: true,
      legendOrder: 8,
    },
    {
      name: 'On-Demand (receiving no discount)',
      label: 'On-Demand (receiving no discount)',
      y: percentage(props.portfolioAllocation.on_demand_percentage),
      color: '#fc5454',
      labelColor: LABEL_TEXT_LIGHT,
      legendSelected: true,
      legendOrder: 9,
    },
  ];
});
</script>

<template>
  <PanelSection header="Portfolio Allocation">
    <div class="portfolioAllocation row">
      <div>
        <DonutChart name="Portfolio Distribution" :data="data" />
        <div class="blendedDiscount">
          <div>
            <TextTooltip placement="bottom" class="mr-2">
              Blended Discount:
              <template #tooltip>
                <strong>Blended Discount</strong> measures the overall discount received from the various savings
                instruments in your portfolio. This is influenced by multiple factors including commitment type, 1 or 3
                year term, virtual machine series, region, etc.
              </template>
            </TextTooltip>
            <strong>
              <Percentage :value="percentage(portfolioAllocation.effective_discount_percentage)" />
            </strong>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="legend">
          <div v-for="item in data" :key="item.label" class="legendItem">
            <div :style="{ 'background-color': item.color }"></div>
            <div>
              <TextTooltip placement="bottom" size="lg" class="text-nowrap">
                {{ item.label }}
                <template
                  v-if="
                    item.id === 'Other' &&
                    portfolioAllocation.on_demand_negotiated_savings_discount_percentage &&
                    portfolioAllocation.on_demand_negotiated_savings_discount_percentage > 0
                  "
                  #tooltip
                >
                  <div class="tooltipLegend">
                    Negotiated Savings (receiving
                    <Percentage
                      :value="percentage(portfolioAllocation.on_demand_negotiated_savings_discount_percentage)"
                    />
                    discount)
                  </div>
                </template>
              </TextTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PanelSection>
</template>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.portfolioAllocation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.blendedDiscount {
  display: flex;
  justify-content: center;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-size: 1.2rem;
  line-height: 1.4;
}

.legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;

  > div {
    padding-bottom: 1.2rem;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: column;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  margin-right: 1.2rem;
}

.legendItem > div:first-child {
  width: 17px;
  height: 17px;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.legendItem > div:last-child {
  font-size: 0.8rem;
}

.tooltipLegend {
  font-size: 12px;
}
</style>
