<script setup lang="ts">
import type { DailySavingsDay, SeriesData } from '@azure/services/savings.models';
import type { MonthStart } from '@console/services/api.models';

import moment from 'moment';
import { computed, onMounted, ref, watch } from 'vue';

import * as chartUtilities from '@console/components/charts/utility';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const props = defineProps<{
  dailySavings: DailySavingsDay[];
  monthStart: MonthStart;
}>();

const xAxis = computed(() => {
  const month = moment.utc(props.monthStart.key).format('MMM');
  const monthAndDay = (dailySavingDay: DailySavingsDay) => {
    return `${month} ${dailySavingDay.day}`;
  };
  const categories = props.dailySavings.map(d => monthAndDay(d));
  return { categories };
});

const yAxis = computed(() => {
  const maxValue = props.dailySavings?.reduce((prev, current) => {
    var totalSavings =
      (current.base_savings ?? 0) +
      (current.smart_savings ?? 0) +
      (current.inherited_savings ?? 0) +
      (current.total_other_savings ?? 0) +
      (current.unbilled_savings ?? 0);
    return prev > totalSavings ? prev : totalSavings;
  }, 0);
  const minValue = props.dailySavings?.reduce((prev, current) => {
    var totalSavings =
      (current.base_savings ?? 0) +
      (current.smart_savings ?? 0) +
      (current.inherited_savings ?? 0) +
      (current.total_other_savings ?? 0) +
      (current.unbilled_savings ?? 0);
    return prev < totalSavings ? prev : totalSavings;
  }, 0);

  const decimalPlaces = chartUtilities.decimalPlacesNeededForChartMax(maxValue);
  return [
    {
      min: minValue,
      title: {
        text: null,
      },
      labels: {
        format: '<span style="font-size:12px">${value:,.' + decimalPlaces + 'f}</span>', // eslint-disable-line
      },
    },
  ];
});

const tooltip = computed(() => {
  return chartUtilities.tooltipWithTotal({
    totalLabel: 'Total Savings',
    valueFormatter: v => NumberHelpers.formatDollars(v),
  });
});

const filteredSeries = computed<SeriesData[]>(() => legend.value.filter(s => s.legendSelected));

const series = computed(() => {
  // compute
  const base = props.dailySavings.map(a => a.base_savings);
  const smart = props.dailySavings.map(a => a.smart_savings);
  const inherited = props.dailySavings.map(a => a.inherited_savings);
  const other = props.dailySavings.map(a => a.total_other_savings);
  const unbilled = props.dailySavings.map(a => a.unbilled_savings);

  const seriesData = [
    {
      label: 'Smart Savings',
      name: 'Smart Savings',
      type: 'column',
      stacking: 'normal',
      data: smart,
      color: '#00c58c',
      legendOrder: 1,
      legendSelected: true,
      tooltip: {
        valuePrefix: '$',
      },
    },
    {
      label: 'Base Savings',
      name: 'Base Savings',
      type: 'column',
      stacking: 'normal',
      data: base,
      color: '#8fffdf',
      legendOrder: 2,
      legendSelected: true,
      tooltip: {
        valuePrefix: '$',
      },
    },
    {
      label: 'Inherited Savings',
      name: 'Inherited Savings',
      type: 'column',
      stacking: 'normal',
      data: inherited,
      color: '#d9fff4',
      legendOrder: 3,
      legendSelected: true,
      tooltip: {
        valuePrefix: '$',
      },
    },
    {
      label: 'Unbilled Savings',
      name: 'Unbilled Savings',
      type: 'column',
      stacking: 'normal',
      data: unbilled,
      color: '#a5a4bf',
      legendOrder: 4,
      legendSelected: true,
      tooltip: {
        valuePrefix: '$',
      },
    },
    {
      label: 'Other Savings',
      name: 'Other Savings',
      type: 'column',
      stacking: 'normal',
      data: other,
      color: '#e6e6e6',
      legendOrder: 5,
      legendSelected: true,
      tooltip: {
        valuePrefix: '$',
      },
    },
  ];

  return (
    seriesData
      .map(s => ({
        ...s,
        label: s.name,
        type: 'column',
        stacking: 'normal',
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
      }))
      // Remove any series without any data points
      .filter(s => s.data.some(d => !!d))
  );
});

const legend = ref<SeriesData[]>([]);

const buildLegend = () => {
  legend.value = series.value;
};

onMounted(buildLegend);
watch(series, buildLegend);
</script>

<template>
  <PanelSection header="Daily Savings">
    <div>
      <ChartLegend v-model="legend" />
      <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
    </div>
  </PanelSection>
</template>
