/* eslint-disable camelcase */

import type { BillingOfferingVariant } from '../api.models';
import type { AwsOrganizationId } from '@shared/services/namedIds';

export interface SavingsSpendCoverageBreakdown {
  base_target?: number;

  unbilled_spend_coverage?: number;
  unbilled_spend_coverage_percentage?: number;
  inherited_spend_coverage?: number;
  inherited_spend_coverage_percentage?: number;
  base_spend_coverage?: number;
  base_spend_coverage_percentage?: number;
  flex_spend_coverage?: number;
  flex_spend_coverage_percentage?: number;
  flex_boost_spend_coverage?: number;
  flex_boost_spend_coverage_percentage?: number;
  smart_spend_coverage?: number;
  smart_spend_coverage_percentage?: number;
  spot_spend_coverage?: number;
  spot_spend_coverage_percentage?: number;

  overall_spend_coverage?: number;
  overall_spend_coverage_percentage?: number;
}

export interface ComputeAllDiscountsSpendCoverageTrend {
  capacity_block_reservation_usage?: number;
  capacity_block_reservation_usage_percentage?: number;
  spot?: number;
  spot_percentage?: number;
}

export interface ComputeBaseFlexSpendCoverageTrend extends ComputeAllDiscountsSpendCoverageTrend {
  month_start: string;
  variant: BillingOfferingVariant;
  on_demand?: number;
  on_demand_percentage?: number;
  flex?: number;
  flex_percentage?: number;
  flex_boost?: number;
  flex_boost_percentage?: number;
  base_compute_savings_plans?: number;
  base_compute_savings_plans_percentage?: number;
  base_ec2_instance_savings_plans?: number;
  base_ec2_instance_savings_plans_percentage?: number;
  base_convertible_reserved_instances?: number;
  base_convertible_reserved_instances_percentage?: number;
  base_standard_reserved_instances?: number;
  base_standard_reserved_instances_percentage?: number;
  unbilled?: number;
  unbilled_percentage?: number;
  base_spend_coverage_target?: number;

  overall_spend_coverage_percentage?: number;
}

export interface ComputeInheritedBaseSmartSpendCoverageTrend extends ComputeAllDiscountsSpendCoverageTrend {
  month_start: string;
  variant: BillingOfferingVariant;
  on_demand?: number;
  on_demand_percentage?: number;
  inherited_compute_savings_plans?: number;
  inherited_compute_savings_plans_percentage?: number;
  inherited_ec2_instance_savings_plans?: number;
  inherited_ec2_instance_savings_plans_percentage?: number;
  inherited_convertible_reserved_instances?: number;
  inherited_convertible_reserved_instances_percentage?: number;
  inherited_standard_reserved_instances?: number;
  inherited_standard_reserved_instances_percentage?: number;
  base_compute_savings_plans?: number;
  base_compute_savings_plans_percentage?: number;
  smart_compute_savings_plans?: number;
  smart_compute_savings_plans_percentage?: number;
  smart_ec2_instance_savings_plans?: number;
  smart_ec2_instance_savings_plans_percentage?: number;
  smart_convertible_reserved_instances?: number;
  smart_convertible_reserved_instances_percentage?: number;
  smart_standard_reserved_instances?: number;
  smart_standard_reserved_instances_percentage?: number;
  unbilled?: number;
  unbilled_percentage?: number;
  base_spend_coverage_target?: number;

  overall_spend_coverage_percentage?: number;
}

export interface CustomerSpendCoverageTrend {
  active_organizations?: number;
}

export interface NonComputeSpendCoverageTrend {
  month_start: string;
  on_demand?: number;
  on_demand_percentage?: number;
  smart_reserved_coverage?: number;
  smart_reserved_coverage_percentage?: number;
  inherited_reserved_coverage?: number;
  inherited_reserved_coverage_percentage?: number;
  overall_spend_coverage_percentage?: number;
}

export type ComputeSpendCoverageTrend = ComputeBaseFlexSpendCoverageTrend &
  ComputeInheritedBaseSmartSpendCoverageTrend &
  CustomerSpendCoverageTrend;

export type SpendCoverageTrend = ComputeSpendCoverageTrend & NonComputeSpendCoverageTrend;

export interface MonthStart {
  key: string;
  friendly_name: string;
}

/* ================================ non-compute savings dashboard ================================ */
export interface NonComputeNetSavingsTrendMonth {
  month_start: string;
  service_usage: number | null;
  net_savings: number | null;
  effective_savings_rate_percentage: number | null;
  baseline_effective_savings_rate_percentage: number | null;
}

export interface NonComputeSpendCoverageSummary {
  inherited_spend_coverage: number;
  inherited_spend_coverage_percentage: number;
  smart_spend_coverage: number;
  smart_spend_coverage_percentage: number;
  overall_spend_coverage: number;
  overall_spend_coverage_percentage: number;
}

export interface NonComputeDailySpendCoverageDay {
  day: number;
  service_usage: number | null;
  inherited_spend_coverage: number | null;
  smart_spend_coverage: number | null;
  overall_spend_coverage_percentage: number | null;
}

export interface NonComputeDailySavingsDay {
  day: number;
  inherited_savings: number | null;
  smart_savings: number | null;
}

export interface NonComputeKeyMetrics {
  portfolio_actions: number;
  reserved_instance_utilization_percentage: number;
}

export interface AwsOrganizationNonComputeSavingsBreakdownPeriod {
  month_start: string;
  period_title: string;
  sort_order: number;
  is_finalized: boolean;
  service_usage: number;
  service_usage_normalized: number;
  service_usage_delta: number | null;
  service_usage_delta_percentage: number | null;
  service_spend: number;
  service_spend_normalized: number;
  gross_savings: number;
  gross_savings_normalized: number;
  inherited_savings: number | null;
  inherited_savings_normalized: number | null;
  smart_savings: number | null;
  smart_savings_normalized: number | null;
  savings_share: number | null;
  savings_share_normalized: number | null;
  net_savings: number | null;
  net_savings_normalized: number | null;
  net_savings_delta: number | null;
  net_savings_delta_percentage: number | null;
  incremental_savings: number | null;
  incremental_savings_normalized: number | null;
}

export interface EffectiveSavingsRate {
  current: number;
  prior_to_subscription: number | null;
  is_prior_to_subscription_overridden: boolean;

  current_all_discounts: number | null;
  prior_to_subscription_all_discounts: number | null;
  is_prior_to_subscription_all_discounts_overridden: boolean;

  actual_management_fees: number | null;
  estimated_management_fees: number | null;
}

export interface SavingsSummary {
  ec2_reserved_instance_savings: number;
  savings_plan_savings: number | null;
  savings_plan_native_discount_savings: number | null;
  savings_plan_private_discount_savings: number | null;
  total_savings: number;
}

export interface NonComputeSavingsSummary {
  reservation_savings: number;
  total_savings: number;
}

export interface NonComputePortfolioAllocation {
  inherited_one_year_percentage?: number;
  inherited_one_year_discount_percentage?: number;
  inherited_three_year_percentage?: number;
  inherited_three_year_discount_percentage?: number;
  smart_one_year_percentage?: number;
  smart_one_year_discount_percentage?: number;
  smart_three_year_percentage?: number;
  smart_three_year_discount_percentage?: number;
  on_demand_percentage?: number;
  effective_discount_percentage?: number;
}
export interface AwsOrganizationNonComputeSavingsDashboard {
  version: number | null;
  is_finalized: boolean;
  data_through_date: string;
  subscription_start_date: string | null;
  savings_summary: NonComputeSavingsSummary;
  effective_savings_rate: EffectiveSavingsRate;
  savings_breakdown_periods: AwsOrganizationNonComputeSavingsBreakdownPeriod[];
  key_metrics: NonComputeKeyMetrics;
  daily_savings: NonComputeDailySavingsDay[];
  daily_spend_coverage: NonComputeDailySpendCoverageDay[];
  daily_spend_coverage_by_region: { [key: string]: NonComputeDailySpendCoverageDay[] };
  daily_spend_coverage_by_match_key: { [key: string]: NonComputeDailySpendCoverageDay[] };
  spend_coverage_summary: NonComputeSpendCoverageSummary;
  portfolio_distribution: NonComputePortfolioAllocation;
  net_savings_trend: NonComputeNetSavingsTrendMonth[];
  spend_coverage_trend: NonComputeSpendCoverageTrend[];
  spend_coverage_trend_by_region: { [key: string]: NonComputeSpendCoverageTrend[] };
  spend_coverage_trend_by_match_key: { [key: string]: NonComputeSpendCoverageTrend[] };
  savings_impacted_by_inherited: boolean;
  pre_subscription_effective_savings_rate_days?: number;
}

export interface NonComputeSavingsDashboardResponse {
  id: string;
  aws_organization_id: AwsOrganizationId;
  month_start: string;
  dashboard: AwsOrganizationNonComputeSavingsDashboard;
  selected_timeframe: MonthStart;
  available_timeframes: MonthStart[];
}

export interface CommitmentDiscountSavingsBreakdownPeriod {
  month_start: string;
  period_title: string;
  sort_order: number;
  is_finalized: boolean;
  compute_usage: number;
  compute_usage_normalized: number;
  commitment_discountable_usage?: number;
  commitment_discountable_usage_normalized?: number;
  spot_usage?: number;
  spot_usage_normalized?: number;
  capacity_block_reservation_usage?: number;
  capacity_block_reservation_usage_normalized?: number;
  compute_usage_delta?: number;
  compute_usage_delta_percentage?: number;
  compute_spend: number;
  compute_spend_normalized: number;
  gross_savings: number;
  gross_savings_normalized: number;
  total_billing_category_savings?: number;
  total_billing_category_savings_normalized?: number;
  total_non_billing_category_savings?: number;
  total_non_billing_category_savings_normalized?: number;
  unbilled_savings?: number;
  unbilled_savings_normalized?: number;
  inherited_savings?: number;
  inherited_savings_normalized?: number;
  base_savings?: number;
  base_savings_normalized?: number;
  flex_savings?: number;
  flex_savings_normalized?: number;
  flex_boost_savings?: number;
  flex_boost_savings_normalized?: number;
  smart_savings?: number;
  smart_savings_normalized?: number;
  savings_share?: number;
  savings_share_normalized?: number;
  external_management_fee?: number;
  external_management_fee_normalized?: number;
  net_savings?: number;
  net_savings_normalized?: number;
  net_savings_delta?: number;
  net_savings_delta_percentage?: number;
  incremental_savings?: number;
  incremental_savings_normalized?: number;
  reseller_total_compute_usage?: number;
  reseller_total_compute_usage_normalized?: number;
  reseller_total_compute_usage_delta?: number;
  reseller_total_compute_usage_delta_percentage?: number;
  reseller_end_customer_spend_coverage?: number;
  reseller_end_customer_spend_coverage_normalized?: number;
  reseller_end_customer_spend_coverage_delta?: number;
  reseller_end_customer_spend_coverage_delta_percentage?: number;
}

export interface ResellerSavingsBreakdownPeriod {
  reseller_total_compute_usage?: number;
  reseller_total_compute_usage_normalized?: number;
  reseller_total_compute_usage_delta?: number;
  reseller_total_compute_usage_delta_percentage?: number;
  reseller_end_customer_spend_coverage?: number;
  reseller_end_customer_spend_coverage_normalized?: number;
  reseller_end_customer_spend_coverage_delta?: number;
  reseller_end_customer_spend_coverage_delta_percentage?: number;
}

export type AwsOrganizationSavingsBreakdownPeriod = CommitmentDiscountSavingsBreakdownPeriod &
  ResellerSavingsBreakdownPeriod;

export interface KeyMetrics {
  portfolio_actions: number;
  ec2_instance_changes: number;
  reserved_instance_utilization_percentage: number;
  savings_plan_utilization_percentage: number | null;
}

export interface DailySavingsDay {
  day: number;
  unbilled_savings: number | null;
  inherited_savings: number | null;
  base_savings: number | null;
  flex_savings: number | null;
  flex_boost_savings: number | null;
  smart_savings: number | null;
  other_private_rate_savings: number | null;
}

export interface DailySpendCoverageDay {
  day: number;
  compute_usage: number | null;
  unbilled_spend_coverage: number | null;
  inherited_spend_coverage: number | null;
  base_spend_coverage: number | null;
  flex_spend_coverage: number | null;
  flex_boost_spend_coverage: number | null;
  smart_spend_coverage: number | null;
  spot_spend_coverage: number | null;
  overall_spend_coverage_percentage: number | null;
}

export interface ComputeAllDiscountsPortfolioAllocation {
  spot_percentage?: number;
  spot_discount_percentage?: number;
  capacity_block_reservation_percentage?: number;
  capacity_block_reservation_discount_percentage?: number;
  on_demand_discount_percentage?: number;
}

export interface ComputeBaseFlexPortfolioAllocation extends ComputeAllDiscountsPortfolioAllocation {
  unbilled_one_year_percentage?: number;
  unbilled_one_year_discount_percentage?: number;
  unbilled_three_year_percentage?: number;
  unbilled_three_year_discount_percentage?: number;
  base_one_year_percentage?: number;
  base_one_year_discount_percentage?: number;
  base_three_year_percentage?: number;
  base_three_year_discount_percentage?: number;
  flex_one_year_percentage?: number;
  flex_one_year_discount_percentage?: number;
  flex_three_year_percentage?: number;
  flex_three_year_discount_percentage?: number;
  flex_boost_one_year_percentage?: number;
  flex_boost_one_year_discount_percentage?: number;
  flex_boost_three_year_percentage?: number;
  flex_boost_three_year_discount_percentage?: number;
  on_demand_percentage?: number;
  effective_discount_percentage?: number;
}

export interface ComputeInheritedBaseSmartPortfolioAllocation extends ComputeAllDiscountsPortfolioAllocation {
  unbilled_one_year_percentage?: number;
  unbilled_one_year_discount_percentage?: number;
  unbilled_three_year_percentage?: number;
  unbilled_three_year_discount_percentage?: number;
  inherited_one_year_percentage?: number;
  inherited_one_year_discount_percentage?: number;
  inherited_three_year_percentage?: number;
  inherited_three_year_discount_percentage?: number;
  base_one_year_percentage?: number;
  base_one_year_discount_percentage?: number;
  base_three_year_percentage?: number;
  base_three_year_discount_percentage?: number;
  smart_one_year_percentage?: number;
  smart_one_year_discount_percentage?: number;
  smart_three_year_percentage?: number;
  smart_three_year_discount_percentage?: number;
  on_demand_percentage?: number;
  effective_discount_percentage?: number;
}

export type ComputePortfolioAllocation = ComputeBaseFlexPortfolioAllocation &
  ComputeInheritedBaseSmartPortfolioAllocation;

export type PortfolioAllocation = ComputePortfolioAllocation & NonComputePortfolioAllocation;

export interface NetSavingsTrendMonth {
  month_start: string;
  compute_usage: number | null;
  net_savings: number | null;
  effective_savings_rate_percentage: number | null;
  baseline_effective_savings_rate_percentage: number | null;
  active_organizations: number | null;
}

export interface ArbitrageOpportunity {
  end_customer_spend_coverage_percentage: number;
  arbitrage_spend_coverage_percentage: number;
  uncovered_usage_percentage: number;
}

export interface ArbitrageOpportunityTrendMonth {
  month_start: string;
  end_customer_spend_coverage: number;
  arbitrage_spend_coverage: number;
  uncovered_usage: number;
}

export type AwsDiscountInstrumentBreakdown = {
  // Reserved Instance Savings
  ec2_reserved_instance_savings?: number;
  ec2_reserved_instance_savings_normalized?: number;
  private_rate_ec2_reserved_instance_savings?: number;
  private_rate_ec2_reserved_instance_savings_normalized?: number;
  ec2_standard_reserved_instance_volume_discount_savings?: number;
  ec2_standard_reserved_instance_volume_discount_savings_normalized?: number;
  // Savings Plan
  savings_plan_savings?: number;
  savings_plan_savings_normalized?: number;
  private_rate_savings_plan_savings?: number;
  private_rate_savings_plan_savings_normalized?: number;
  total_commitment_discount_savings?: number;
  total_commitment_discount_savings_normalized?: number;
  // Ec2 Spot
  ec2_spot_savings?: number;
  ec2_spot_savings_normalized?: number;
  private_rate_ec2_spot_savings?: number;
  private_rate_ec2_spot_savings_normalized?: number;
  // Fargate Spot
  fargate_spot_savings?: number;
  fargate_spot_savings_normalized?: number;
  private_rate_fargate_spot_savings?: number;
  private_rate_fargate_spot_savings_normalized?: number;
  total_spot_savings?: number;
  total_spot_savings_normalized?: number;
  // Other Private Rate Savings
  private_rate_ec2_on_demand_savings?: number;
  private_rate_ec2_on_demand_savings_normalized?: number;
  private_rate_fargate_on_demand_savings?: number;
  private_rate_fargate_on_demand_savings_normalized?: number;
  private_rate_lambda_on_demand_savings?: number;
  private_rate_lambda_on_demand_savings_normalized?: number;
  private_rate_ec2_capacity_block_reservation_savings?: number;
  private_rate_ec2_capacity_block_reservation_savings_normalized?: number;
  total_other_private_rate_savings?: number;
  total_other_private_rate_savings_normalized?: number;
  total_savings?: number;
  total_savings_normalized?: number;
};

export interface AwsOrganizationComputeSavingsDashboard {
  version: number | null;
  is_finalized: boolean;
  data_through_date: string;
  subscription_start_date: string | null;
  has_non_commitment_discount_savings: boolean;
  variant: BillingOfferingVariant;
  savings_summary: SavingsSummary;
  savings_summary_all_discounts?: SavingsSummary;
  effective_savings_rate: EffectiveSavingsRate;
  savings_breakdown_periods: AwsOrganizationSavingsBreakdownPeriod[];
  savings_breakdown_periods_all_discounts?: AwsOrganizationSavingsBreakdownPeriod[];
  discount_instrument_breakdown?: AwsDiscountInstrumentBreakdown;
  key_metrics: KeyMetrics;
  daily_savings: DailySavingsDay[];
  daily_savings_all_discounts: DailySavingsDay[];
  daily_spend_coverage: DailySpendCoverageDay[];
  daily_spend_coverage_all_discounts?: DailySpendCoverageDay[];
  spend_coverage_summary: SavingsSpendCoverageBreakdown;
  spend_coverage_summary_all_discounts?: SavingsSpendCoverageBreakdown;
  portfolio_distribution: PortfolioAllocation;
  portfolio_distribution_all_discounts?: PortfolioAllocation;
  net_savings_trend: NetSavingsTrendMonth[];
  net_savings_trend_all_discounts?: NetSavingsTrendMonth[];
  spend_coverage_trend: ComputeSpendCoverageTrend[];
  spend_coverage_trend_all_discounts?: ComputeSpendCoverageTrend[];
  savings_impacted_by_inherited: boolean;
  pre_subscription_effective_savings_rate_days: number | null;
  pre_subscription_effective_savings_rate_all_discounts_days: number | null;
  arbitrage_opportunity: ArbitrageOpportunity;
  arbitrage_opportunity_trend: ArbitrageOpportunityTrendMonth[];
  ec2_cyclicality_detected: boolean;
  global_cyclicality_detected: boolean;
}

export interface ComputeSavingsDashboardResponse {
  id: string;
  aws_organization_id: AwsOrganizationId;
  month_start: string;
  dashboard: AwsOrganizationComputeSavingsDashboard;
  selected_timeframe: MonthStart;
  available_timeframes: MonthStart[];
}

export type AwsOrganizationSummaryDetail = {
  aws_organization_id: string;
  aws_organization_friendly_name: string;
  management_aws_account_number: string;
  effective_savings_rate: number;
  baseline_effective_savings_rate: number | null;
  compute_usage: number;
  compute_usage_normalized: number;
  compute_usage_delta: number | null;
  compute_usage_delta_percentage: number | null;
  compute_spend: number;
  compute_spend_normalized: number;
  gross_savings: number;
  gross_savings_normalized: number;
  unbilled_savings: number | null;
  unbilled_savings_normalized: number | null;
  inherited_savings: number | null;
  inherited_savings_normalized: number | null;
  base_savings: number | null;
  base_savings_normalized: number | null;
  flex_savings: number | null;
  flex_savings_normalized: number | null;
  flex_boost_savings: number | null;
  flex_boost_savings_normalized: number | null;
  smart_savings: number | null;
  smart_savings_normalized: number | null;
  savings_share: number | null;
  savings_share_normalized: number | null;
  net_savings: number | null;
  net_savings_normalized: number | null;
  net_savings_delta: number | null;
  incremental_savings: number | null;
  incremental_savings_normalized: number | null;
  net_savings_delta_percentage: number | null;
  lifetime_savings: number | null;
  incremental_lifetime_savings: number | null;
  reseller_total_compute_usage: number | null;
  reseller_total_compute_usage_normalized: number | null;
  reseller_total_compute_usage_delta: number | null;
  reseller_total_compute_usage_delta_percentage: number | null;
  reseller_end_customer_spend_coverage: number | null;
  reseller_end_customer_spend_coverage_normalized: number | null;
  reseller_end_customer_spend_coverage_delta: number | null;
  reseller_end_customer_spend_coverage_delta_percentage: number | null;
};

export type AwsOrganizationSummary = {
  active_organizations: number;
  pending_organizations: number | null;
  details: AwsOrganizationSummaryDetail[];
  details_all_discounts?: AwsOrganizationSummaryDetail[];
};

export type CustomerAwsComputeSavingsDashboard = {
  version: number | null;
  is_finalized: boolean;
  data_through_date: string;
  variant: BillingOfferingVariant;
  has_non_commitment_discount_savings: boolean;
  savings_summary: SavingsSummary;
  savings_summary_all_discounts?: SavingsSummary;
  effective_savings_rate: EffectiveSavingsRate;
  effective_savings_rate_all_discounts?: EffectiveSavingsRate;
  savings_breakdown_periods: AwsOrganizationSavingsBreakdownPeriod[];
  savings_breakdown_periods_all_discounts?: AwsOrganizationSavingsBreakdownPeriod[];
  discount_instrument_breakdown?: AwsDiscountInstrumentBreakdown;
  key_metrics: KeyMetrics;
  daily_savings: DailySavingsDay[];
  daily_savings_all_discounts?: DailySavingsDay[];
  daily_spend_coverage: DailySpendCoverageDay[];
  daily_spend_coverage_all_discounts?: DailySpendCoverageDay[];
  spend_coverage_summary: SavingsSpendCoverageBreakdown;
  spend_coverage_summary_all_discounts?: SavingsSpendCoverageBreakdown;
  portfolio_distribution: PortfolioAllocation;
  portfolio_distribution_all_discounts?: PortfolioAllocation;
  net_savings_trend: NetSavingsTrendMonth[];
  net_savings_trend_all_discounts?: NetSavingsTrendMonth[];
  spend_coverage_trend: ComputeSpendCoverageTrend[];
  spend_coverage_trend_all_discounts?: ComputeSpendCoverageTrend[];
  arbitrage_opportunity: ArbitrageOpportunity;
  arbitrage_opportunity_trend: ArbitrageOpportunityTrendMonth[];
  organization_summary: AwsOrganizationSummary;
};

export type CustomerAwsComputeSavingsDashboardResponse = {
  id: string;
  customer_id: string;
  month_start: string;
  dashboard: CustomerAwsComputeSavingsDashboard;
  selected_timeframe: MonthStart;
  available_timeframes: MonthStart[];
};

export type AwsComputeSavingsDashboard = AwsOrganizationComputeSavingsDashboard | CustomerAwsComputeSavingsDashboard;
export type AwsSavingsDashboard = AwsComputeSavingsDashboard | AwsOrganizationNonComputeSavingsDashboard;

export const isOrganizationComputeDashboard = (
  dashboard?: AwsSavingsDashboard
): dashboard is AwsOrganizationComputeSavingsDashboard => {
  return !!dashboard && 'ec2_cyclicality_detected' in dashboard;
};

export const isCustomerComputeDashboard = (
  dashboard?: AwsSavingsDashboard
): dashboard is CustomerAwsComputeSavingsDashboard => {
  return !!dashboard && 'organization_summary' in dashboard;
};

export const isComputeDashboard = (
  dashboard?: AwsSavingsDashboard
): dashboard is CustomerAwsComputeSavingsDashboard | AwsOrganizationComputeSavingsDashboard =>
  isOrganizationComputeDashboard(dashboard) || isCustomerComputeDashboard(dashboard);

export const isNonComputeDashboard = (
  dashboard?: AwsSavingsDashboard
): dashboard is AwsOrganizationNonComputeSavingsDashboard => {
  return !!dashboard && !isOrganizationComputeDashboard(dashboard) && !isCustomerComputeDashboard(dashboard);
};

export type AwsComputeSavingsDashboardResponse =
  | ComputeSavingsDashboardResponse
  | CustomerAwsComputeSavingsDashboardResponse;

export type AwsSavingsDashboardResponse = AwsComputeSavingsDashboardResponse | NonComputeSavingsDashboardResponse;
