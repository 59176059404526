import type { Options } from 'highcharts';
import type { App, DefineComponent } from 'vue';

import Highcharts from 'highcharts';
import PatternFill from 'highcharts/modules/pattern-fill';
import HighchartsVue from 'highcharts-vue';

interface HighChartsProps {
  options: Options;
}

declare module 'vue' {
  export interface GlobalComponents {
    chart: DefineComponent<HighChartsProps>;
  }
}

declare module 'highcharts' {
  // The global patterns added by the pattern-fill module isn't added to the highcharts module, so do that here
  export const patterns: Highcharts.PatternObject[];
}

export const useHighcharts = (app: App) => {
  // @ts-expect-error - Valid while in compatibility mode
  app.use(HighchartsVue, { tagName: 'chart' });
};

PatternFill(Highcharts);

Highcharts.setOptions({
  credits: {
    enabled: false,
  },
  legend: {
    itemStyle: {
      fontWeight: '500',
    },
  },
  chart: {
    backgroundColor: '#fafafa',
    style: {
      fontFamily:
        '"Neurial Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
      fontSize: '16px',
    },
  },
  lang: {
    thousandsSep: ',',
  },
  tooltip: {
    borderWidth: 1,
    backgroundColor: 'rgba(247,247,247,0.85)',
    borderColor: 'rgb(173, 181, 189)',
  },
  plotOptions: {
    area: {
      lineWidth: 2,
    },
    bar: {
      borderRadius: 0,
    },
    column: {
      borderRadius: 0,
    },
    pie: {
      borderRadius: 0,
      dataLabels: {
        connectorShape: 'fixedOffset',
      },
    },
    line: {
      lineWidth: 2,
    },
    spline: {
      lineWidth: 2,
    },
    waterfall: {
      borderRadius: 0,
    },
  },
  xAxis: {
    labels: {
      distance: 8,
      style: {
        color: '#666666',
        fontSize: '11px',
      },
    },
    lineColor: '#ccd6eb',
  },
  yAxis: {
    labels: {
      distance: 8,
      style: {
        color: '#666666',
        fontSize: '11px',
      },
    },
  },
  colorAxis: {
    labels: {
      distance: 8,
      style: {
        color: '#666666',
        fontSize: '11px',
      },
    },
    maxColor: '#003399',
    minColor: '#e6ebf5',
  },
  scrollbar: {
    barBorderRadius: 0,
    barBorderWidth: 1,
    buttonsEnabled: true,
    height: 14,
    margin: 0,
    rifleColor: '#333',
    trackBackgroundColor: '#f2f2f2',
    trackBorderRadius: 0,
  },
});
