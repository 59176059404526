<script setup lang="ts">
import type { ComputeSpendCoverageTrend } from '@azure/services/savings.models';
import type { LegendSeries } from '@console/components/charts/ChartLegend.vue';
import type Highcharts from 'highcharts';
import type { TooltipFormatterContextObject, TooltipOptions } from 'highcharts';

import Big from 'big.js';
import he from 'he';
import moment from 'moment';
import { computed, onMounted, ref, watch } from 'vue';

import * as chartUtilities from '@console/components/charts/utility';
import NumberHelpers from '@shared/utilities/number_helpers';

import ChartLegend from '@console/components/charts/ChartLegend.vue';
import SeriesChart from '@console/components/charts/SeriesChart.vue';
import PanelSection from '@shared/design/panels/PanelSection.vue';

const SERIES_NAME_BASE_TARGET = 'Base Target';

type Series = Highcharts.SeriesOptions & LegendSeries;

type Props = {
  spendCoverageTrend: ComputeSpendCoverageTrend[];
};

const props = defineProps<Props>();

const xAxis = computed(() => ({
  categories: props.spendCoverageTrend.map(sc => moment.utc(sc.month_start).format('MMM YYYY')),
}));

const yAxis = computed(() => [
  {
    min: 0,
    title: {
      text: null,
    },
    labels: {
      format: chartUtilities.getCurrencyFormat(),
    },
  },
]);

const filteredSeries = computed(() => legend.value.filter(s => s.legendSelected));

const tooltip = computed<TooltipOptions>(() => {
  return {
    ...chartUtilities.defaultTooltip,
    formatter: function () {
      const categoryIndex = xAxis.value.categories.indexOf(this.x as string);
      const month = months.value[categoryIndex];
      const points = this.points ?? [];
      const xLabel = this.x?.toString() ?? '';

      const excludedPoints = points.filter(r => r.series.name === SERIES_NAME_BASE_TARGET);
      const includedPoints = points.filter(r => r.series.name !== SERIES_NAME_BASE_TARGET);
      const totalValue = Number(includedPoints.reduce((sum, p) => sum.add(p.y ?? 0), Big(0)));
      const excludedRowsHtml = excludedPoints.map(p => pointToHtml(p, month)).join('');
      const includedRowsHtml = includedPoints.map(p => pointToHtml(p, month)).join('');

      let totalHtml = '';
      if (includedPoints.length >= 2) {
        totalHtml = `
          <div style="padding-top: 2px">
            ${chartUtilities.encodedTooltipRow(`Compute Usage`, totalValue, NumberHelpers.formatDollars)}
          </div>
          `;
      }

      let overallCoverageHtml = '';
      if (month.overall_spend_coverage_percentage) {
        overallCoverageHtml = chartUtilities.encodedTooltipRow(
          'Overall Spend Coverage',
          month.overall_spend_coverage_percentage * 100,
          v => NumberHelpers.formatNumber(v, 1) + '%'
        );
      }

      return `
            <div>
              <div style="font-size: 12px;">
                <strong>${he.encode(xLabel)}</strong>
              </div>
              ${excludedRowsHtml /* excluded rows are shown first */}
              ${includedRowsHtml}
              ${totalHtml}
              ${overallCoverageHtml}
            </div>
          `;
    },
  };
});

const pointToHtml = (point: TooltipFormatterContextObject, month: ComputeSpendCoverageTrend) => {
  let percentage: number | undefined;
  var seriesDef = seriesDefs.find(s => s && s.name === point.series.name);
  if (seriesDef) {
    const percentageField = seriesDef.field + '_percentage';
    if (percentageField in month) {
      percentage = month[percentageField as keyof ComputeSpendCoverageTrend] as number;
    }
  }
  return chartUtilities.encodedTooltipRow(
    point.series.name,
    point.y,
    NumberHelpers.formatDollars,
    point.color as string,
    percentage
  );
};

interface SeriesDef {
  name: string;
  color: string;
  type: 'area' | 'line';
}

interface ComputeSeriesDef extends SeriesDef {
  // false positive, defined in utility.d.ts
  // eslint-disable-next-line no-undef
  field: keyof OptionalNumericFields<ComputeSpendCoverageTrend>;
}

// This is the order the items will be stacked in the chart (the first will be on the top)
const seriesDefs: ComputeSeriesDef[] = [
  { name: 'On-Demand', field: 'on_demand_normalized', color: '#fc5454', type: 'area' },
  {
    name: 'Unbilled',
    field: 'unbilled_spend_coverage_normalized',
    color: '#e6e6e6',
    type: 'area',
  },
  { name: 'Smart: Reservations', field: 'smart_reservation_spend_coverage_normalized', color: '#00c58c', type: 'area' },
  {
    name: 'Smart: Savings Plans',
    field: 'smart_savings_plan_spend_coverage_normalized',
    color: '#8fffdf',
    type: 'area',
  },
  { name: 'Base: Savings Plans', field: 'base_savings_plan_spend_coverage_normalized', color: '#5c54ff', type: 'area' },
  {
    name: 'Inherited: Reservations',
    field: 'inherited_reservation_spend_coverage_normalized',
    color: '#fcbe2c',
    type: 'area',
  },
  {
    name: 'Inherited: Savings Plans',
    field: 'inherited_savings_plan_spend_coverage_normalized',
    color: '#a7a3ff',
    type: 'area',
  },
  { name: SERIES_NAME_BASE_TARGET, field: 'base_target', color: '#495057', type: 'line' },
];

const months = computed(() => {
  const firstMonths = props.spendCoverageTrend.slice(0, -1);
  const lastMonth = props.spendCoverageTrend.slice(-1)[0] ?? {};
  // Don't include the last month if there aren't any fields other than month_start (i.e. no spend coverage data for the month)
  const includeLastMonth = Object.keys(lastMonth).some(key => key !== 'month_start');
  const months = includeLastMonth ? [...firstMonths, lastMonth] : firstMonths;
  return months;
});

const areaOptions = {
  fillOpacity: '0.5',
  dashStyle: 'solid',
  stacking: 'normal',
};
const lineOptions = {
  dashStyle: 'dash',
};

const series = computed(() =>
  seriesDefs
    .map((s, index) => {
      const data = months.value.map(month => month[s.field] ?? null);
      return {
        label: s.name,
        // Use null as the default value to ensure that days without values are still shown on the x axis
        data,
        // Reverse the order of the legend so that the last item (bottom) is the leftmost
        legendOrder: seriesDefs.length - index,
        legendSelected: true,
        tooltip: {
          valuePrefix: '$',
        },
        marker: {
          enabled: chartUtilities.hasSingleDataPoint(data),
        },
        // Apply the options specific to the series type
        ...(s.type === 'area' ? areaOptions : lineOptions),
        // Allow the series to override any of the above properties
        ...s,
      };
    })
    // Remove any series without any data points
    .filter(s => s.data.some(d => !!d))
);

const legend = ref<Series[]>([]);

const buildLegend = () => {
  legend.value = series.value;
};

onMounted(buildLegend);
watch(series, buildLegend);
</script>

<template>
  <PanelSection header="Spend Coverage Trend">
    <div>
      <ChartLegend v-model="legend" />
      <SeriesChart :x-axis="xAxis" :y-axis="yAxis" :series="filteredSeries" :tooltip="tooltip" />
      <div class="d-flex flex-row-reverse">
        <small>(normalized)</small>
      </div>
    </div>
  </PanelSection>
</template>
