<script setup lang="ts">
import type { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import type { Ec2InventoryItem, Ec2InventoryMetrics } from '@console/services/aws/arm/api.models';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from '@ag-grid-community/vue3';
import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';

import { getEc2Inventory, getEc2InventoryMetrics } from '@aws/services/awsArmService';
import { useVuexStore } from '@console/state/vuex/store';

import ScheduleCell from './ScheduleCell.vue';
import ResourceMetrics from '@aws/components/arm/resources/ResourceMetrics.vue';
import PageLayout from '@console/PageLayout.vue';
import PageHeader from '@shared/design/PageHeader.vue';

useHead({
  title: 'Resources',
});

const store = useVuexStore();

const rowData = ref<Ec2InventoryItem[]>([]);
const metricData = ref<Ec2InventoryMetrics>({
  total_resources: 0,
  scheduled_resources: 0,
  running_resources: 0,
  stopped_resources: 0,
});

const colDefs = ref<Array<ColDef<Ec2InventoryItem>>>([
  {
    colId: 'instance_id',
    headerName: 'Resource ID',
    valueGetter: i => i.data?.instance_id,
    filter: true,
    tooltipField: 'instance_id',
  },
  {
    headerName: 'Resource Name',
    valueGetter: i => i.data?.display_name,
    sort: 'asc',
    sortIndex: 0,
    filter: true,
    tooltipField: 'display_name',
  },
  {
    headerName: 'Account',
    valueGetter: i => `${i.data?.aws_account_number} (${i.data?.friendly_name})`,
    tooltipValueGetter: i => `${i.data?.aws_account_number} (${i.data?.friendly_name})`,
    filter: true,
  },
  {
    colId: 'state',
    headerName: 'State',
    valueGetter: i => {
      const state = i.data?.last_state_name;
      return state ? state.charAt(0).toUpperCase() + state?.slice(1) : '';
    },
    tooltipValueGetter: i => {
      const state = i.data?.last_state_name;
      return state ? state.charAt(0).toUpperCase() + state?.slice(1) : '';
    },
    filter: true,
  },
  {
    colId: 'schedule',
    headerName: 'Schedule',
    cellRenderer: ScheduleCell,
    cellRendererParams: (params: ICellRendererParams) => {
      return {
        awsOrgId: store.getters['aws/selectedOrganizationId'],
        inventoryId: params.data?.arm_aws_ec2_inventory_id,
        title: `Resource Schedule: ${params.data?.instance_id} (${params.data?.display_name})`,
      };
    },
    resizable: false,
  },
  {
    headerName: 'Notification Receiver',
    valueGetter: i => i.data?.notification_email,
    tooltipField: 'notification_email',
    filter: true,
  },
]);

const isLoading = ref(true);
const awsOrgId = computed(() => store.getters['aws/selectedOrganizationId']);

onMounted(async () => {
  isLoading.value = true;
  rowData.value = await getEc2Inventory(awsOrgId.value);
  metricData.value = await getEc2InventoryMetrics(awsOrgId.value);
  isLoading.value = false;
});
</script>

<template>
  <PageHeader wrap-utility>
    <div class="d-flex gap-3 align-items-center mr-2">
      <h1>Resources</h1>
    </div>
  </PageHeader>
  <PageLayout :loading="isLoading">
    <template #default>
      <ResourceMetrics :metric-data="metricData" class="metricsRow" />
      <AgGridVue
        :row-data="rowData"
        :column-defs="colDefs"
        suppress-movable-columns
        :auto-size-strategy="{
          type: 'fitGridWidth',
          columnLimits: [
            {
              colId: 'instance_id',
              maxWidth: 200,
            },
            {
              colId: 'schedule',
              maxWidth: 100,
            },
            {
              colId: 'state',
              maxWidth: 100,
            },
          ],
        }"
        :modules="[ClientSideRowModelModule]"
        :ensure-dom-order="true"
        :tooltip-show-delay="1000"
        tooltip-show-mode="whenTruncated"
        style="width: 100%; height: 85%"
        class="ag-theme-quartz"
        enable-cell-text-selection
      />
    </template>
  </PageLayout>
</template>

<style lang="scss" scoped>
.metricsRow {
  padding-bottom: 15px;
}
</style>
